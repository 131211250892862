import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background: linear-gradient(135.7deg, #393E45 -2.06%, #0A0D10 96.01%);
  min-height: 100vh;
  padding: 0 25%;
`

export const LoginWrapper = styled.div`
  flex: 1;
  width: 100%;
  max-width: 710px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LogoText = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 30px;
  text-align: center;
  color: #FFFFFF;
  text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
`

export const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.15);
  border-radius: 40px;
  padding: 64.5px 136.2px 44.5px 137.75px;
  width: 100%;
  max-width: 710px;
`

export const ForgotLink = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  text-decoration-line: underline;
  color: #898793;
  cursor: pointer;
`

export const FooterText = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 45px;
  text-align: center;
  letter-spacing: -0.3px;
  color: #FFFFFF;
`

export const ButtonWrapper = styled.div`
  text-align: center;
`