import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { requestMeditationsPlaylist, requestDeleteMeditationsPlaylist } from '../../lib/mediations'

import Page from '../../components/Page'
import TabContainer, { Tab } from '../../components/TabContainer'
import Spacer from '../../components/Spacer'
import NoData from '../../components/NoData'
import Button from '../../components/Button'
import Modal from '../../components/Modal'

import { Text } from '../crews/CrewsNew'

import NoteImage from '../../assets/images/note.svg'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 95px;
  flex-direction: column;

  & > * {
    margin: 16px 0;

    &:first-child {
      margin-top: 0;
    }

    &:first-child {
      margin-bottom: 0;
    }
  }
`
const PlaylistWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 95px;
`

const Playlist = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 25px 95px;
  border-top: 1px solid #DFE5EE;

  &:last-child {
    border-bottom: 1px solid #DFE5EE;
  }
`

const Song = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 20px;
  color: #121217;
`

const Artist = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #6C7B8A;
`

export const DeleteButton = styled.div`
  display: flex;
  justify-content: center;
`

const SevenKnotsMeditations = () => {
  const [mediations, setMediations] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [modalData, setModalData] = useState(false)

  const fetchMediations = useCallback(async () => {
    const mediationsResponse = await requestMeditationsPlaylist();

    setMediations(mediationsResponse)
  }, [setMediations])

  useEffect(() => {
    fetchMediations()
  }, [fetchMediations])

  const deleteModal = meditation => {
    setModalData(meditation)
    setShowDeleteModal(true)
  }

  const deleteSong = (id) => async () => {
    await requestDeleteMeditationsPlaylist(id)
    setModalData(false)
    setShowDeleteModal(false)
    fetchMediations()
  }

  return <Page>
    <TabContainer tabs={[
      <Tab type="blue" to="/7knots/meditations" >MEDITATIONS</Tab>,
      <Tab type="green" to="/7knots/playlists" data-active>UPLOAD</Tab>
    ]}>
      {mediations &&
      <>
        <Wrapper>
          <Link to="/7knots/upload"><Button width="287px">+ NEW MEDITATION</Button></Link>
          <Spacer height="50"/>
        </Wrapper>
          <PlaylistWrapper>
            <Header>
              <Text>Music Selection</Text><Link to="/7knots/playlists/upload"><Button type="white" width="287px">+ ADD SONG</Button></Link>
            </Header>
            <Spacer height="25"/>
            {mediations.map(mediation =>
              <Playlist key={mediation.id}>
                <Song>
                  <Title>{mediation.meta.name}<Spacer width="10"/><img src={NoteImage} alt="note"/></Title>
                  <Spacer height="10"/>
                  <Artist>{mediation.meta.artist}</Artist>
                </Song>
                <Button type="white" width="287px" onClick={() => deleteModal(mediation)}>DELETE</Button>

              </Playlist>
            )}
          </PlaylistWrapper>
          <Modal show={showDeleteModal} close={() => setShowDeleteModal(false)} title="DELETE SONG">
            <Spacer height="50"/>
            <span>
              Are you sure you want to delete <b>{modalData?.meta?.name}</b>?<br/><br/>
              It will be permanently removed.
            </span>
            <Spacer height="82"/>
            <DeleteButton>
              <Button width="287px" onClick={deleteSong(modalData?.id)}>DELETE</Button>
            </DeleteButton>
          </Modal>
      </>}
      {!mediations && <NoData/>}
    </TabContainer>
  </Page>
}


export default SevenKnotsMeditations