import React, { useCallback, useEffect, useState } from 'react'
import { useImmer } from 'use-immer'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import { requestAssigned, requestUpdateCrew, requestCrews } from '../../lib/crews'

import Page from '../../components/Page'
import TabContainer, { Tab } from '../../components/TabContainer'
import Spacer from '../../components/Spacer'
import Button from '../../components/Button'
import Checkbox from '../../components/Checkbox'
import Modal from '../../components/Modal'

import {
  PersonData,
  Name,
  Company,
  CrewCount,
  CrewTitle,
  AssignTable,
} from '../../components/CrewTable'
import Toast from '../../components/Toast'

const Text = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
  letter-spacing: 2.88889px;
  text-transform: uppercase;
  color: #000000;
`

const Wrapper = styled.div`
  padding-left: 90px;
  padding-right: 70px;
`

const AssignRow = styled.div`
  padding: 20px 55px 20px 97px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #DFE5EE;

  &:last-child {
    border-bottom: 1px solid #DFE5EE;
  }
`

const Crews = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;

  & > * {
    margin: 5px 9px;
  }
`

const MemberData = styled.div`
  display: flex;
  justify-content: space-between;
`

const ModalButtonWrapper = styled.div`
  text-align: center;
`

const CrewRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 20px;
`

const CrewData = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

const CrewsAllMembers = () => {
  const [users, setUsers] = useState([])
  const [crews, setCrews] = useState([])
  const [newMember, setNewMember] = useState(false)
  const [newCrews, setNewCrews] = useImmer([])
  const [showCrewsModal, setShowCrewsModal] = useState(false)
  const [isFetching, setIsFetching] = useState(false)


  const fetchUsers = useCallback(async () => {
    const usersRequest = await requestAssigned();

    setUsers(usersRequest)
  }, [setUsers])

  const fetchCrews = useCallback(async () => {
    const crewsRequest = await requestCrews();

    setCrews(crewsRequest)
  }, [setCrews])

  useEffect(() => {
    fetchUsers()
    fetchCrews()
  }, [fetchUsers, fetchCrews])

  if(users.length === 0 || crews.length === 0) {
    return null
  }

  const handleCrewChange = (e, crew) => {
    if(e.target.checked) {
      setNewCrews(draft => {
        draft.push(crew)
      })
    } else {
      setNewCrews(draft => {
        return draft.filter(_crew => _crew.id !== crew.id)
      })
    }
  }

  const openCrewModal = (member) => (e) => {
    setNewMember(member)
    setShowCrewsModal(true)
  }

  const closeCrewModal = () => {
    if(!isFetching) {
      setShowCrewsModal(false)
      setNewMember(false)
      setNewCrews(draft => draft = [])
    }
  }

  const assingMembers = async () => {
    setIsFetching(true);

    try {
      await Promise.all(
        newCrews.map(async crew => {
          await requestUpdateCrew(crew.id, {
            ...crew,
            assignedUsersIds: Array.from(new Set([
              ...crew.assignedAuthzIds,
              newMember.authzId
            ]))
          })
        })
      )

      await fetchUsers()
      await fetchCrews()
    } catch (e) {
      setIsFetching(false);
      closeCrewModal()
      Toast.error("Something went wrong")
      return
    }

    setIsFetching(false);
    closeCrewModal()
    Toast.success("Member assgined")
  }

  return <Page>
    <TabContainer tabs={[
      <Tab type="red" to="/crews/all">ALL CREWS</Tab>,
      <Tab type="blue" to="#" data-active>ALL MEMBERS</Tab>,
      <Tab type="green" to="/crews/unassigned" >UNASSIGNED</Tab>
    ]}>
      <Wrapper>
        <Spacer height="50"/>
        <Text>Members</Text>
      </Wrapper>
      <Spacer height="38"/>
      <div>
        {users.map(member => (
          <AssignRow key={member.id}>
            <MemberData>
              <PersonData>
                <Name>{member.name}</Name>
                <Company>{member?.user_metadata?.company}</Company>
              </PersonData>
              <Button width="50px" onClick={openCrewModal(member)}>+</Button>
            </MemberData>
            <Crews>
              {member.linkedCrews.map(crew => <Button key={crew} type="outline">
                {crew}
              </Button>)}
            </Crews>
          </AssignRow>
        ))}
      </div>
      <Spacer height="50"/>
      <Modal show={showCrewsModal} close={closeCrewModal} title="CREW NAME">
        <AssignTable>
          {crews.filter(crew =>  newMember.linkedCrews?.indexOf(crew.name) === -1).map(crew => (
              <CrewRow key={crew.id}>
                <CrewTitle>{crew.name}</CrewTitle>
                <CrewData>
                  <CrewCount>{crew.assignedAuthzIds.length}</CrewCount>
                  <Spacer width="75"/>
                  <Checkbox onChange={e => handleCrewChange(e, crew)} value={newCrews.find(_crew => _crew.id === crew.id)}/>
                </CrewData>
              </CrewRow>
          ))}
        </AssignTable>
        <Spacer height="35"/>
        <ModalButtonWrapper>
          <Button onClick={assingMembers} disabled={isFetching} width="287px">{isFetching ? 'Saving...' : 'Save'}</Button>
        </ModalButtonWrapper>
      </Modal>
    </TabContainer>
  </Page>
}


export default CrewsAllMembers