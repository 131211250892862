import styled from 'styled-components'

export default styled.textarea`
  background: #FFFFFF;
  border: 2px solid #DFE5EE;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  padding: 18px 24px 28px 24px;
  width: 100%;
  resize: vertical;
`