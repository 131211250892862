import React from 'react'
import styled from 'styled-components'
import { convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { useHistory } from 'react-router-dom'

import { requestCreatePost, requestImageUpload } from '../../lib/posts'

import Page from '../../components/Page'
import TabContainer, { Tab } from '../../components/TabContainer'
import PostInput from '../../components/PostInput'
import Toast from '../../components/Toast'

const Wrapper = styled.div`
  padding: 50px 176px 233px 128px;
`

const PostsNew = () => {
  const history = useHistory()

  const createPost = async ({
    $title,
    $subTitle,
    editorState,
    imageFile
  }) => {
    if(!$title.current.value || !$subTitle.current.value) {
      Toast.error("Please enter title and subtitle")
      return;
    }
    const rawContentState = convertToRaw(editorState.getCurrentContent());

    const markup = draftToHtml(
      rawContentState
    );

    try {
      let post

      try {
        post = await requestCreatePost({
          title: $title.current.value,
          subTitle: $subTitle.current.value,
          content: markup
        })
      } catch (e) {
        Toast.error("Something went wrong while creating post")
      }

      if(imageFile) {
        await requestImageUpload(post.id, imageFile)
      }

      history.push(`/posts/view/${post.id}`)
    } catch (e) {
      Toast.error("Something went wrong while uploading image")
    }
  }

  return <Page>
    <TabContainer tabs={[
      <Tab type="blue" to="#" data-active>NEW POST</Tab>,
      <Tab type="green" to="/posts/feed">FEED</Tab>
    ]}>
      <Wrapper>
        <PostInput buttonText="POST" submit={createPost}/>
      </Wrapper>
    </TabContainer>
  </Page>
}


export default PostsNew