import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import selectAuthorization from '../selectors/selectAuthorization'

const PrivateRoute = ({
  component: Component,
  ...rest
}) => {
  const authorization = useSelector(selectAuthorization)

  return (
    <Route
      {...rest}
      component={() => {
        if (!authorization.authorized) {
          return <Redirect to="/login" />;
        }

        return (
          <Route
            {...rest}
            component={Component}
          />
        );
      }
  }
    />
  )
};

export default PrivateRoute;
