import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'

import { requestPosts } from '../../lib/posts'

import Page from '../../components/Page'
import TabContainer, { Tab } from '../../components/TabContainer'
import Input from '../../components/Input'
import Spacer from '../../components/Spacer'
import PostItem from '../../components/PostItem'
import NoData from '../../components/NoData'

import SearchIconImage from '../../assets/images/search-icon.svg'

const InputWrapper = styled.div`
  padding-left: 71px;
`

const PostsNew = () => {
  const [posts, setPosts] = useState(false)
  const location = useLocation()
  const history = useHistory()
  const { q } = queryString.parse(location.search)

  const fetchPosts = useCallback(async (q) => {
    const postsResponse = await requestPosts(q)

    setPosts(postsResponse)
  }, [setPosts])

  useEffect(() => {
    fetchPosts(q)
  }, [fetchPosts, q])

  const handleInput = e => {
    const keyCode = e.keyCode || e.which;

    if(keyCode === 13) {
      if(e.target.value) {
        history.replace(`/posts/feed?q=${e.target.value}`)
      } else {
        history.replace(`/posts/feed`)
      }
    }
  }

  if(posts.length === 0) {
    return null
  }

  return <Page>
    <TabContainer tabs={[
      <Tab type="blue" to="/posts/new">NEW POST</Tab>,
      <Tab type="green" to="#" data-active>FEED</Tab>
    ]}>
      {posts && <>
        <InputWrapper>
          <Input width="678px" placeholder="Search Posts" icon={SearchIconImage} onKeyPress={handleInput} defaultValue={q}/>
        </InputWrapper>
        <Spacer height="38"/>
        {posts.map(post => <PostItem key={post.id} post={post}/>)}
      </>}

      {!posts && <NoData/>}
    </TabContainer>
  </Page>
}


export default PostsNew