
import {
  createStore, compose, applyMiddleware, combineReducers,
} from 'redux';
import thunk from 'redux-thunk';


import localStorageMiddleware from '../middleware/localStorageMiddleware';
import authorization from '../ducks/authorization'
import crews from '../ducks/crews'


const composeEnhancers = typeof window === 'object'
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    trace: true, traceLimit: 25,
  }) : compose;

const middleware = applyMiddleware(thunk, localStorageMiddleware);

const rootReducer = combineReducers({
  authorization,
  crews,
});

const initialState = {

};

const getInitialState = () => {
  if (localStorage.getItem('sessionData')) {
    const session = JSON.parse(localStorage.getItem('sessionData'));

    if(Object.keys(session).length!== 0) {
      initialState.authorization = {
        accessToken: session.authResult.accessToken,
        idToken: session.authResult.idToken,
        expiresAt: session.authResult.expiresAt,
        authorized: !(session.authResult.expiresAt < Date.now())
      }
    }
  }

  return initialState;
};


export default createStore(rootReducer, getInitialState(), composeEnhancers(
  middleware,
));
