import React from 'react';
import { toast } from "react-toastify";
import styled from 'styled-components';

import Spacer from './Spacer';

import close from '../assets/images/close.svg'
import checkbox from '../assets/images/checkbox.svg'

const ICON_MAP = {
  success: checkbox,
  error: close
}

const TOAST_STYLE_MAP = {
  success: 'Toastify__toast--success',
  error: 'Toastify__toast--error',
  warning: 'Toastify__toast--warning',
}

const ToastWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 20px 15px;
  border-radius: 1px;
  box-shadow: 0 1px 10px 0 rgba(0,0,0,.1), 0 2px 15px 0 rgba(0,0,0,.05);
  display: flex;
  /* justify-content: space-between; */
  max-height: 800px;
  overflow: hidden;
  font-family: sans-serif;
  cursor: pointer;
  direction: ltr;
  border-radius: 10px;

  color: #fff;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 2.88889px;
  text-transform: uppercase;
`;

const CustomToast = ({ mode, children }) => (
  <ToastWrapper className={TOAST_STYLE_MAP[mode]}>
    {ICON_MAP[mode] && (
      <>
        <img src={ICON_MAP[mode]} alt="icon"/>
        <Spacer width="20"/>
      </>
    )}

    {children}
  </ToastWrapper>
)



export default {
  success: (text) => toast(<CustomToast mode="success">{text}</CustomToast>) ,
  error: (text) => toast(<CustomToast mode="error">{text}</CustomToast>) ,
  warning: (text) => toast(<CustomToast mode="warning">{text}</CustomToast>) ,
}