import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link, useParams, useHistory } from 'react-router-dom'

import { requestPost, requestDeletePost, requestPostStatus } from '../../lib/posts'

import Page from '../../components/Page'
import TabContainer, { Tab } from '../../components/TabContainer'
import Spacer from '../../components/Spacer'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import NoData from '../../components/NoData'
import Toast from '../../components/Toast'

import {
  Header,
  Headline,
  Date,
  Content,
  DeleteButton
} from '../../components/PostItem'

import BackImage from '../../assets/images/back.svg'

export const Wrapper = styled.div`
  padding: 0 113px 0 140px;
`

const Top = styled.div`
  padding: 0 50px 0 71px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Back = styled(Link)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 2.88889px;
  text-transform: uppercase;
  color: #000000;

  text-decoration: none;
  display: flex;
  align-items: center;
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`

const PostsNew = () => {
  const [post, setPost] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showActionModal, setShowActionModal] = useState(false)
  const params = useParams()
  const history = useHistory()

  const fetchPost = useCallback(async () => {
    const postResponse = await requestPost(params.id)

    setPost(postResponse)
  }, [setPost, params.id])

  useEffect(() => {
    fetchPost()
  }, [fetchPost])

  const deletePost = async () => {
    await requestDeletePost(post.id)
    history.push('/posts/feed')
  }

  const changePostStatus = async () => {
    await requestPostStatus(post.id, post.status === 'drafted')
    setShowActionModal(false)
    await fetchPost()

    Toast.success(`Post was ${post.status !== 'drafted' ? 'drafted' : 'published'}`)
  }

  const date = new window.Date(post.createdAt).toLocaleDateString(
    'en-gb',
    {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }
  );

  return <Page>
    <TabContainer tabs={[
      <Tab type="blue" to="/posts/new">NEW POST</Tab>,
      <Tab type="green" to="/posts/feed" data-active>FEED</Tab>
    ]}>
        {!post && <NoData/>}
        {post && <>
          <Spacer height="45"/>
        <Top>
          <Back to="/posts/feed">
            <img src={BackImage} alt="back"/><Spacer width="30"/>Back
          </Back>
          <ButtonWrapper>
            <Button type="white" width="140px" onClick={() => setShowDeleteModal(true)}>Delete</Button>
            <Spacer width="15"/>
            <Button type="white" width="140px" onClick={() => setShowActionModal(true)}>{post.status === 'drafted' ? 'PUBLISH' : 'DRAFT'}</Button>
            <Spacer width="15"/>
            <Link to={`/posts/edit/${post.id}`}>
              <Button width="140px">Edit</Button>
            </Link>
          </ButtonWrapper>
        </Top>
        <Spacer height="45"/>
        <Wrapper noborder>
          <Header>
            <Headline to={`/posts/view/${post.id}`}>{post.title}</Headline>
            <Date>{date}</Date>
          </Header>
          <Spacer height="40"/>
          <Content dangerouslySetInnerHTML={{__html: post.content}}/>
          <Spacer height="35"/>
          <Modal show={showDeleteModal} close={() => setShowDeleteModal(false)} title="DELETE POST">
            <Spacer height="20"/>
            <span>
              Are you sure you want to delete <b>{post.title}</b>?<br/><br/>
              It will be permanently removed.
            </span>
            <Spacer height="82"/>
            <DeleteButton>
              <Button width="287px" onClick={deletePost}>DELETE</Button>
            </DeleteButton>
          </Modal>
          <Modal show={showActionModal} close={() => setShowActionModal(false)} title={post.status === 'drafted' ? 'PUBLISH POST' : 'DRAFT POST'}>
            <Spacer height="20"/>
            <span>
              Are you sure you want to {post.status === 'drafted' ? 'publish' : 'draft'} <b>{post.title}</b>?<br/><br/>
            </span>
            <Spacer height="82"/>
            <DeleteButton>
              <Button width="287px" onClick={changePostStatus}>{post.status === 'drafted' ? 'publish' : 'draft'}</Button>
            </DeleteButton>
          </Modal>
        </Wrapper>
        </>}
    </TabContainer>
  </Page>

}


export default PostsNew