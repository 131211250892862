import { API, getToken } from '../constants/config'

export const requestCrews = async () => {
  const request = await fetch(`${API}/crews`, {
      headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${getToken()}`
      }
  });

  return await request.json()
}

export const requestUnnasignedForCrew = async (crewId) => {
  const request = await fetch(`${API}/crews/${crewId}/users/unassigned`, {
      headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${getToken()}`
      }
  });

  return await request.json()
}

export const requestUnnasigned = async (crewId) => {
  const request = await fetch(`${API}/crews/users/unassigned`, {
      headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${getToken()}`
      }
  });

  return await request.json()
}

export const requestAssigned = async (crewId) => {
  const request = await fetch(`${API}/crews/users/assigned`, {
      headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${getToken()}`
      }
  });

  return await request.json()
}

export const requestUpdateCrew = async (crewId, updatedCrew) => {
  const request = await fetch(`${API}/crews/${crewId}`, {
      method: 'PATCH',
      headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`
      },
      body: JSON.stringify(updatedCrew)
  });

  return await request.json()
}
export const requestCreateCrew = async (crew) => {
  const request = await fetch(`${API}/crews`, {
      method: 'POST',
      headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`
      },
      body: JSON.stringify(crew)
  });

  return await request.json()
}
