import styled, { css } from 'styled-components'

const DefaultButton = styled.button`
  background: linear-gradient(154.75deg, #FFC387 -2.44%, #FF6A61 107.72%);
  border-radius: 100px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 2.88889px;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 15px 0;
  text-align: center;
  outline: 0;
  border: 0;
  cursor: pointer;
  ${props => props.width && css`width: ${props.width};`}

  &[disabled] {
    opacity: 0.6;
  }
`

const WhiteButton = styled(DefaultButton)`
  background: #FFFFFF;
  box-shadow: 0px 6px 36px rgba(59, 74, 116, 0.2);
  color: #121217;
`

const OutlineButton = styled(DefaultButton)`
  background: none;
  border: 1px solid #000000;
  color: #121217;
  padding: 15px;
`

const TYPE_MAP = {
  default: DefaultButton,
  white: WhiteButton,
  outline: OutlineButton
}

const Button = ({type = 'default', children, ...props}) => {
  const MappedButton = TYPE_MAP[type];

  return <MappedButton {...props}>{children}</MappedButton>
}

export default Button