import auth0 from "auth0-js";

import AUTH_CONFIG from "../constants/auth.config";

class Auth {
  WebAuth = new auth0.WebAuth({
    audience: 'https://api.firetribes.app/',
    domain: AUTH_CONFIG.domain,
    clientID: AUTH_CONFIG.clientId,
    redirectUri: AUTH_CONFIG.callbackUrl,
    responseType: "token id_token",
    scope: "openid offline_access profile email read:current_user update:current_user_metadata read:users read:user_idp_tokens"
  });

  login = ({username, password}) => new Promise((resolve, reject) => {
    this.WebAuth.client.login({
      realm: 'Username-Password-Authentication',
      username,
      password,
      scope: 'read:order write:order openid'
    },
    function(err, authResult) {
      resolve({err,authResult})
    })
  })

  renewSession = () => new Promise((resolve, reject) => {
    this.WebAuth.renewAuth({}, (err, authResult) => {
      resolve({err,authResult})
    });
  })

  changePassword = (email) => new Promise((resolve, reject) => {
    this.WebAuth.changePassword({
      email,
      connection: 'Username-Password-Authentication',
    }, (err) => {
      resolve(err);
    });
  })

  logout = () => {
    this.WebAuth.logout({
      returnTo: window.location.origin+'/login'
    });
  };

  checkSession = async () => {
    return this.WebAuth.checkSession({}, function (err, authResult) {
      // debugger
    });
  }
}

export default new Auth();