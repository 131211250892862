import React from 'react';
import styled from 'styled-components'
import ReactModal from 'react-modal';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

import Spacer from './Spacer'

import CloseImage from '../assets/images/close.svg'

const ModalWrapper = styled.div`
  padding: 33px 37px 58px 86px;
  border-radius: 40px;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.15);
  min-height: 360px;
  max-width: 765px;
  max-height: 80vh;
  width:100%;
  height:100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  span {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 20px;
    color: #000000;
  }

  b {
    font-weight: 500;
  }

  p {
    font-weight: 600;
    line-height: 29px;
  }
`

const Title = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 30px;
`

const Close = styled.img.attrs({
  src: CloseImage,
  alt: "close"
})`
  align-self: flex-end;
  cursor: pointer;
`;

const onOpen = () => {
  disableBodyScroll(document.querySelector('.overlay'))
}

const onClose = () => {
  clearAllBodyScrollLocks(document.querySelector('.overlay'))
}

const Modal = ({
  show,
  close,
  title,
  children
}) => {
  return (
    <ReactModal
      isOpen={show}
      close={close}
      className="modal"
      id="modal"
      overlayClassName="overlay"
      onRequestClose={close}
      ariaHideApp={false}
      onAfterOpen={onOpen}
      onAfterClose={onClose}
    >
      <ModalWrapper>
        <Close onClick={close} />
        <Spacer height="15"/>
        <Title>{title}</Title>
        {children}
      </ModalWrapper>
    </ReactModal>
  )
}

export default Modal