import React from 'react'
import styled from 'styled-components'
import {Link, useLocation} from 'react-router-dom'

import Spacer from './Spacer'

const PageWrapper = styled.div`
  padding: 30px 0 0 50px;
  display: flex;
  background: linear-gradient(135.7deg, #393E45 -2.06%, #0A0D10 96.01%);
  min-height: 100vh;
`

const Navigation = styled.div`
  margin-right: 90px;
  min-width: 170px;
`

const NavLink = styled(Link)`
  display: block;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
  letter-spacing: 2.88889px;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 57px;
  text-decoration: none;

  &[data-active=true], &:hover {
    background: linear-gradient(#FFC387, #FF6A61);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`
const CurrentPage = styled.div`
  align-self: flex-end;
  padding-right: 50px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const PAGE_MAP = {
  'crews': "CREWS",
  'posts': "POSTS",
  '7knots': "SEVEN KNOTS",
}

const getPageName = pathname => {
  const key = Object.keys(PAGE_MAP).find(key => pathname.indexOf(key) !== -1)

  return PAGE_MAP[key]
}

const Page = ({children}) => {
  const location = useLocation();

  const isActive = (key) => {
    return location.pathname.indexOf(key) !== -1
  }

  return (
    <PageWrapper>
      <Navigation>
        <img src="/assets/images/logo.svg" alt="logo" height="83px"/>
        <Spacer height="77"/>
        <NavLink to="/crews/all" data-active={isActive('crews')}>CREWS</NavLink>
        <NavLink to="/posts/new" data-active={isActive('posts')}>POSTS</NavLink>
        <NavLink to="/7knots/meditations" data-active={isActive('7knots')}>7 KNOTS</NavLink>
      </Navigation>
      <Content>
        <CurrentPage><NavLink data-active to="./">{getPageName(location.pathname)}</NavLink></CurrentPage>
        {children}
      </Content>
    </PageWrapper>
  )
}

export default Page