import React, { useState, useCallback } from 'react'
import { useImmer } from "use-immer";
import styled from 'styled-components'
import { useDispatch } from 'react-redux'

import { requestUnnasignedForCrew, requestUpdateCrew } from '../lib/crews'

import { getCrews } from '../ducks/crews'

import Spacer from './Spacer'
import Modal from './Modal'
import Button from './Button'
import Checkbox from './Checkbox'
import Toast from './Toast'
import Icon from './Icon'

import ArrowDown from '../assets/images/arrow-down.svg'
import ArrowUp from '../assets/images/arrow-up.svg'
import DeleteImage from '../assets/images/delete.svg'
import MdiEdit from '../assets/images/mdi_edit.svg'
import Input from './Input';

const Wrapper = styled.div``;

const Header = styled.div`
  padding: 0 180px 15px 104px;
  border-bottom: 1px solid #DFE5EE;
  display: flex;
  justify-content: space-between;
`;

const THead = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 20px;
  color: #000000;
`

const Row = styled.div`
  border-bottom: 1px solid #DFE5EE;
  padding: 0 50px 30px 0;
`

const RowHeader = styled.div`
  padding: 36px 0 20px 104px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const CrewTitle = styled.div`
  display: flex;
`

export const Title = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
`

export const CrewCount = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
  letter-spacing: 2.88889px;
  text-transform: uppercase;
  color: #6C7B8A;
`;

const AccordeonToggle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const AccordeonButton = styled.img`
  cursor: pointer;
`

const RowContent = styled.div`
  display: ${props => props.isExpanded ? 'flex' : 'none'};
`

const CrewMember = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0 0 0 70px;
  margin-bottom: 10px;

  &:last-child {
    margin: 0
  }
`

const Delete = styled.div`
  cursor: pointer;
`;

export const PersonData = styled.div``;

export const Name = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 2.88889px;
  text-transform: uppercase;
  color: #000000;
`;

export const Company = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
`;

const DeleteButton = styled.div`
  display: flex;
  justify-content: center;
`

const RowSideBar = styled.div`

`;

const ButtonSideBar = styled.div`
  flex: 1;
  align-self: flex-end;
  text-align: right;
`;

export const AssignTable = styled.div`
  border: 2px solid #DFE5EE;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 23px 40px 23px 27px;
  min-height: 340px;
  overflow-y: scroll;
`

export const AssignRow = styled.div`
  margin: 15px 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

const EditIcon = styled(Icon)`
  cursor: pointer;
`

const CrewRow = ({crew}) => {
  const dispatch = useDispatch()
  const [isExpanded, setIsExpanded] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)
  const [modalDeleteData, setModalDeleteData] = useState({})
  const [modalAddData, setModalAddData] = useState([])
  const [newMembers, setNewMembers] = useImmer([])

  const ArrowImage = isExpanded ? ArrowUp : ArrowDown;

  const toggle = () => {
    setIsExpanded(!isExpanded)
  }

  const handleDelete = useCallback((member, crew) => () => {
    setModalDeleteData({
      member,
      crew
    })
    setShowDeleteModal(true)
  }, [setModalDeleteData, setShowDeleteModal])

  const handleAdd = useCallback((crew) => async () => {
    const crews = await requestUnnasignedForCrew(crew.id)
    setModalAddData(crews)
    setShowAddModal(true)
  }, [setModalAddData, setShowAddModal])

  const handleAssigneeChange = (e, id) => {
    if(e.target.checked) {
      setNewMembers(draft => {
        draft.push(id)
      })
    } else {
      setNewMembers(draft => {
        draft = draft.filter(_id => _id !== id)
      })
    }
  }

  const handleAssing = async () => {
    await requestUpdateCrew(crew.id, {
      ...crew,
      assignedUsersIds: [
        ...crew.assignedAuthzIds,
        ...newMembers
      ]
    })

    await dispatch(getCrews())

    setShowAddModal(false)
    setModalAddData([])
  }

  const handleDeletion = async () => {
    await requestUpdateCrew(crew.id, {
      ...crew,
      assignedUsersIds: crew.assignedAuthzIds.filter(id => id !== modalDeleteData.member.authzId)
    })

    await dispatch(getCrews())

    Toast.success(`${modalDeleteData.member.name} Deleted`);

    setShowDeleteModal(false)
    setModalDeleteData({})
  }

  const handleInput = async (e) => {
    if(e.which === 27) {
      setIsEdit(false)
      return
    }

    if(e.which === 13) {
      try {
        const updateRequest = await requestUpdateCrew(crew.id, {
          assignedUsersIds: crew.assignedAuthzIds,
          name: e.target.value
        })

        if(updateRequest.statusCode === 400) {
          throw new Error();
        }

        await dispatch(getCrews())

        setIsEdit(false)

        Toast.success(`Crew renamed`);
      } catch (e) {
        Toast.error("Something went wrong")
      }

    }
  }

  return (
    <Row>
        <RowHeader>
          {!isEdit && <CrewTitle>
            <EditIcon size="24px" color="#353A40" icon="mdiPencil" onClick={() => setIsEdit(true)}/><Spacer width="26"/><Title>{crew.name}</Title>
          </CrewTitle>}
          {isEdit && <CrewTitle>
            <Input defaultValue={crew.name} icon={MdiEdit} onKeyUp={handleInput}/>
          </CrewTitle>}
          <AccordeonToggle>
            <CrewCount>{crew.assignedAuthzIds.length}</CrewCount>
            <Spacer width="195"/>
            <AccordeonButton onClick={toggle} src={ArrowImage} alt='toggle'/>
          </AccordeonToggle>
        </RowHeader>
        <RowContent isExpanded={isExpanded}>
          <RowSideBar>
            {crew.assignedUsers.map(member => <CrewMember key={member.user_id}>
              <Delete>
                <img src={DeleteImage} alt="delete" onClick={(handleDelete(member, crew))}/>
              </Delete>
              <Spacer width="20"/>
              <PersonData>
                <Name>{member.name}</Name>
                <Company>{member?.user_metadata?.company}</Company>
              </PersonData>
            </CrewMember>)}
          </RowSideBar>
          <ButtonSideBar>
            <Button type="white" width="287px" onClick={handleAdd(crew)}>+ ADD MEMBER</Button>
          </ButtonSideBar>
        </RowContent>

        {showDeleteModal && <Modal show={showDeleteModal} title="DELETE CREW MEMBER" close={() => setShowDeleteModal(false)}>
          <Spacer height="20"/>
          <span>Are you sure you want to delete</span>
          <Spacer height="30"/>
          <span><b>{modalDeleteData.member.name}</b> from <b>{modalDeleteData.crew.name}</b>?</span>
          <Spacer height="70"/>
          <DeleteButton>
            <Button width="287px" onClick={handleDeletion}>Delete</Button>
          </DeleteButton>
        </Modal>}

        {showAddModal && <Modal show={showAddModal} title="UNASSIGNED MEMBERS" close={() => setShowAddModal(false)}>
          <AssignTable>
            {modalAddData.map(member => (
              <AssignRow>
                <PersonData>
                  <Name>{member.name}</Name>
                  <Company>{member?.user_metadata?.company}</Company>
                </PersonData>
                <Checkbox onChange={e => handleAssigneeChange(e, member.authzId)}/>
              </AssignRow>
            ))}
          </AssignTable>
          <Spacer height="35"/>
          <DeleteButton>
            <Button width="287px" onClick={handleAssing}>SAVE</Button>
          </DeleteButton>
        </Modal>}
    </Row>
  )
}

const CrewTable = ({crews}) => {
  debugger

  return <Wrapper>
    <Header>
      <THead>CREW NAME</THead>
      <THead>CREW MEMBERS</THead>
    </Header>
    {crews.map(crew => (
      <CrewRow key={crew.id} crew={crew}/>
    ))}
  </Wrapper>
}

export default CrewTable