import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import Button from './Button'
import Spacer from './Spacer'
import Modal from './Modal'

import { requestDeletePost } from '../lib/posts'

export const Wrapper = styled.div`
  padding: 0 50px 0 71px;
  border-top: 1px solid #DFE5EE;
`

const New = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #7A7A7A;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Headline = styled(Link)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 2.88889px;
  text-transform: uppercase;
  color: ${props => props.$isNew ? '#44CF94' : '#000000'};
  text-decoration: none;
  text-align: left;
`

export const Date = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  letter-spacing: 2.88889px;
  text-transform: uppercase;
  color: #000000;
`

export const Content = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
`

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const DeleteButton = styled.div`
  display: flex;
  justify-content: center;
`

const PostItem = ({post}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleted, setDeleted] = useState(false)
  const date = new window.Date(post.createdAt).toLocaleDateString(
    'en-gb',
    {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }
  );

  const deletePost = async () => {
    await requestDeletePost(post.id)
    setDeleted(true)
  }

  if(deleted) {
    return null
  }

  return <Wrapper>
    <Spacer height="45"/>
    {post.isNew && <>
      <New>NEW</New>
      <Spacer height="8"/>
    </>}
    <Header>
      <Headline to={`/posts/view/${post.id}`} $isNew={post.isNew}>{post.title}</Headline>
      <Date>{date}</Date>
    </Header>
    <Spacer height="40"/>
    <Content dangerouslySetInnerHTML={{__html: post.subTitle}}/>
    <Spacer height="20"/>
    <Footer>
      <Button type="white" width="287px" onClick={() => setShowDeleteModal(true)}>Delete</Button>
      <Spacer width="55"/>
      <Link to={`/posts/edit/${post.id}`}>
        <Button width="287px">Edit</Button>
      </Link>
    </Footer>
    <Spacer height="35"/>
    <Modal show={showDeleteModal} close={() => setShowDeleteModal(false)} title="DELETE POST">
      <Spacer height="20"/>
      <span>
        Are you sure you want to delete <b>{post.title}</b>?<br/><br/>
        It will be permanently removed.
      </span>
      <Spacer height="82"/>
      <DeleteButton>
        <Button width="287px" onClick={deletePost}>DELETE</Button>
      </DeleteButton>
    </Modal>
  </Wrapper>
}

export default PostItem