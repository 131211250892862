import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './assets/styles/normalize.css'

import Config from '../package.json'
import store from './store';

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import './assets/styles/toast-override.css';
import './assets/styles/modal-override.css';
import './assets/styles/editor-override.css';

import PrivateRoute from './components/PrivateRoute'

import Login from './pages/login/Login';

import CrewsAll from './pages/crews/CrewsAll';
import CrewsUnassigned from './pages/crews/CrewsUnassigned';
import CrewsNew from './pages/crews/CrewsNew';
import CrewsAllMembers from './pages/crews/CrewsAllMembers';

import PostsNew from './pages/posts/PostsNew';
import PostsFeed from './pages/posts/PostsFeed';
import PostView from './pages/posts/PostView';
import PostEdit from './pages/posts/PostEdit';

import SevenKnotsMeditations from './pages/sevenKnots/SevenKnotsMeditations';
import SevenKnotsMeditationEdit from './pages/sevenKnots/SevenKnotsMeditationEdit';
import SevenKnotsMeditationUpload from './pages/sevenKnots/SevenKnotsMeditationUpload';
import SevenKnotsMeditationPlaylist from './pages/sevenKnots/SevenKnotsMeditationPlaylist';
import SevenKnotsMeditationPlaylistUpload from './pages/sevenKnots/SevenKnotsMeditationPlaylistUpload';

import Slide from './components/Slide'

const App = () => {
  console.log(`%c VERSION ${Config.version}  `, 'font-weight: bold; font-size: 50px;color: white; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113)');

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/" exact render={() => <Redirect to="/login"/>} />

          <Route path="/login" exact component={Login} />

          <PrivateRoute path="/crews/all" exact component={CrewsAll}/>
          <PrivateRoute path="/crews/unassigned" exact component={CrewsUnassigned}/>
          <PrivateRoute path="/crews/all-members" exact component={CrewsAllMembers}/>
          <PrivateRoute path="/crews/new" exact component={CrewsNew}/>

          <PrivateRoute path="/posts/new" exact component={PostsNew}/>
          <PrivateRoute path="/posts/feed" exact component={PostsFeed}/>
          <PrivateRoute path="/posts/view/:id" exact component={PostView}/>
          <PrivateRoute path="/posts/edit/:id" exact component={PostEdit}/>

          <PrivateRoute path="/7knots/meditations" exact component={SevenKnotsMeditations}/>
          <PrivateRoute path="/7knots/edit/:id" exact component={SevenKnotsMeditationEdit}/>
          <PrivateRoute path="/7knots/upload" exact component={SevenKnotsMeditationUpload}/>
          <PrivateRoute path="/7knots/playlists" exact component={SevenKnotsMeditationPlaylist}/>
          <PrivateRoute path="/7knots/playlists/upload" exact component={SevenKnotsMeditationPlaylistUpload}/>
        </Switch>
      </Router>
      <ToastContainer position="bottom-right" hideProgressBar closeButton="false" transition={Slide} autoClose={2500} pauseOnHover limit={3}/>
    </Provider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById('root')
);