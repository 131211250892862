import React, { useEffect, useRef } from 'react'
import { useImmer } from 'use-immer'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import { requestUnnasigned, requestCreateCrew } from '../../lib/crews'

import Page from '../../components/Page'
import TabContainer, { Tab } from '../../components/TabContainer'
import Spacer from '../../components/Spacer'
import Button from '../../components/Button'
import Input from '../../components/Input'
import Checkbox from '../../components/Checkbox'
import NoData from '../../components/NoData'

import {
  PersonData,
  Name,
  Company,
  AssignRow,
} from '../../components/CrewTable'

export const Text = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
  letter-spacing: 2.88889px;
  text-transform: uppercase;
  color: #000000;
`

const Wrapper = styled.div`
  padding-left: 90px;
  padding-right: 70px;
`

const Content = styled.div`
  width: 500px;
`

const ButtonWrapper = styled.div`
  text-align: right;
`

const CrewsNew = () => {
  const history = useHistory()
  const [users, setUsers] = useImmer([])
  const [newMembers, setNewMembers] = useImmer([])
  const $crewname = useRef(null);


  useEffect(() => {
    const fetchUsers = async () => {
      const usersRequest = await requestUnnasigned();


      setUsers(users => usersRequest)
    }

    fetchUsers()
  }, [])

  const handleAssigneeChange = (e, id) => {
    if(e.target.checked) {
      setNewMembers(draft => {
        draft.push(id)
      })
    } else {
      setNewMembers(draft => {
        draft = draft.filter(_id => _id !== id)
      })
    }
  }

  const create = async () => {
    if( $crewname.current === null ) {
      return
    }

    await requestCreateCrew({
      name: $crewname.current.value,
      assignedUsersIds: newMembers
    })

    history.push('/crews/all');
  }

  return <Page>
    <TabContainer tabs={[
      <Tab type="red" to="/crews/all"  data-active>ALL CREWS</Tab>,
      <Tab type="blue" to="/crews/all-members">ALL MEMBERS</Tab>,
      <Tab type="green" to="/crews/unassigned">UNASSIGNED</Tab>
    ]}>
      {
        users.length === 0 && <NoData/>
      }
      {
        users.length > 0 && (
          <Wrapper>
            <Spacer height="50"/>
            <Content>
              <Text>CREATE NEW CREW</Text>
              <Spacer height="30"/>
              <Input placeholder="Crew Name Here" width="457px" ref={$crewname}/>
              <Spacer height="50"/>
              <Text>Unassigned Crew Members</Text>
              <Spacer height="45"/>
              {users.map(member => (
                  <AssignRow>
                    <PersonData>
                      <Name>{member.name}</Name>
                      <Company>{member?.user_metadata?.company}</Company>
                    </PersonData>
                    <Checkbox onChange={e => handleAssigneeChange(e, member.authzId)}/>
                  </AssignRow>
                ))}
            </Content>
            <Spacer height="50"/>
            <ButtonWrapper>
              <Button width="287px" onClick={create}>Save</Button>
            </ButtonWrapper>
            <Spacer height="50"/>
          </Wrapper>
        )
      }
    </TabContainer>
  </Page>
}


export default CrewsNew