import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

import Page from '../../components/Page'
import TabContainer, { Tab } from '../../components/TabContainer'
import Spacer from '../../components/Spacer'
import Meditation from '../../components/Meditation'

import { requestMediation } from '../../lib/mediations'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 73px;
`

const SevenKnotsMeditations = () => {
  const [mediation, setMediation] = useState(false)
  const params = useParams()

  const fetchMediation = useCallback(async () => {
    const mediationsResponse = await requestMediation(params.id);

    setMediation(mediationsResponse)
  }, [setMediation, params.id])

  useEffect(() => {
    fetchMediation()
  }, [fetchMediation])

  if(!mediation) {
    return null
  }

  return <Page>
    <TabContainer tabs={[
      <Tab type="blue" to="/7knots/meditations" data-active>MEDITATIONS</Tab>,
      <Tab type="green" to="/7knots/playlists">UPLOAD</Tab>
    ]}>
      <Spacer height="60"/>
      <Wrapper>
        <Meditation key={mediation.id} meditation={mediation} edit/>
      </Wrapper>
    </TabContainer>
  </Page>
}


export default SevenKnotsMeditations