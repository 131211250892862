import React, {useCallback, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import imageCompression from 'browser-image-compression';
import mime from 'mime-types';
import styled, { css } from 'styled-components';
import Cropper from 'react-easy-crop'

import Button from './Button'
import Spacer from './Spacer'
import Toast from './Toast'
import Modal from './Modal'

import { getCroppedImg } from '../utils/canvasUtils'

export const DropZoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DropZoneHolder = styled.div`
  border: 2px solid #DFE5EE;
  box-sizing: border-box;
  border-radius: 8px;
  min-height: 200px;
  width: 450px;
  display: flex;
  cursor: pointer;
  transition: background-color .2s ease-in;
  align-items: center;

  ${props => props.isDragActive && `
    background: rgb(61,203,149, .2);
  `}
  ${props => props.isDragReject && `
    background: rgb(255,0,0, .2);
  `}

  ${props => props.fullwidth && css`width: 100%;`}
`;

export const DropZoneLabelWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  align-items: center;
  padding: 35px 85px;
`;

export const DropZoneLabel = styled.span`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
  word-break: break-all;
`;

export const CropButton = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 20px 69px rgba(59, 74, 116, 0.2);
  border-radius: 20px;
  padding: 10px 0 15px 0;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: #121217;
  margin-bottom: 30px;
  cursor: pointer;

  &:after {
    content: ' ';
    position: relative;
    bottom: 0;
    top: 29px;
    left: -15%;
    right: 0;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: #fff;
    border-bottom: 0;
    margin-left: -10px;
    margin-bottom: -10px;
  }
`

export const CropWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  background: #333;
`
export const CropControl = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;

  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: #121217;
`

export const PreviewImg = styled.img`
  border-radius: 8px;
`

export const allowedImageFiles = [
  mime.lookup('jpeg'),
  mime.lookup('jpg'),
  mime.lookup('gif'),
  mime.lookup('png'),
];

export const allowedAudioFiles = [
  mime.lookup('mp3'),
  "audio/wav",
];

const maxSize = 20;

const DropZone = ({
  items: incomingItems,
  accept,
  uploadHandler,
  image = false,
  fullwidth
}) => {
  const [preview, setPreview] = useState(image);
  const [audioPreview, setAudioPreview] = useState(false);
  const [showImageCropModal, setShowImageCropModal] = useState(false);

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        preview,
        croppedAreaPixels,
        rotation
      )
      setPreview(URL.createObjectURL(croppedImage))
      uploadHandler(null, croppedImage)
      setShowImageCropModal(false)
    } catch (e) {
      console.error(e)
    }
  }, [preview, croppedAreaPixels, rotation, uploadHandler])

  const onDrop = useCallback(acceptedFiles => {
    for (let i = 0; i < acceptedFiles.length; i += 1) {
      // eslint-disable-next-line no-loop-func
      (async function addFile(file) {
        const reader = new FileReader();

        reader.onload = function handleFileRead(image) {
          uploadHandler(image,file)
        };

        if (allowedImageFiles.indexOf(file.type) !== -1) {
          const compressed = await imageCompression(file, {
            maxSizeMB: 1,
          });

          setPreview(URL.createObjectURL(file))

          reader.readAsArrayBuffer(compressed);
        } else {
          setAudioPreview(file)
          reader.readAsArrayBuffer(file);
        }
      }(acceptedFiles[i]));
    }
  }, [uploadHandler]);

  const onDropRejected = useCallback((e) => {
    if(e.map(er => er.errors).flat().find(er => er.code ==="file-invalid-type")) {
      Toast.error("You are trying to upload wrong file type. Please use the correct one.")
      return
    }
    Toast.error('The file you are trying to upload is bigger than 15MB')
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept,
    onDropRejected,
    multiple: false,
    maxSize: maxSize*1000000
  })

  const items = incomingItems.filter(Boolean).length > 0 ? incomingItems.filter(({file, uploading}) => !!file || uploading !== undefined) : [];

  return (
    <DropZoneWrapper>
      {preview  && <>
        <CropButton onClick={() => setShowImageCropModal(true)}>
          Crop and rotate
        </CropButton>
      </>}
      <DropZoneHolder {...getRootProps({className: 'dropzone'})} isDragActive={isDragActive} isDragReject={isDragReject} fullwidth={fullwidth}>
        <input {...getInputProps()} />
        {
          items.length === 0 && !isDragActive && !preview && !audioPreview &&
            <DropZoneLabelWrapper>
              <DropZoneLabel>Drag and Drop or</DropZoneLabel>
              <Spacer height="20"/>
              <Button type="white" width="287px">UPLOAD FILE</Button>
            </DropZoneLabelWrapper>
        }
        {
          !isDragActive && preview && <PreviewImg src={preview} alt="cover" width="100%"/>
        }
        {
          !isDragActive && audioPreview && <DropZoneLabelWrapper>
          <DropZoneLabel>{audioPreview.name}</DropZoneLabel>
        </DropZoneLabelWrapper>
        }
        {
          isDragActive && !isDragReject &&
            <DropZoneLabelWrapper>
              <DropZoneLabel>Drop the files here ...</DropZoneLabel>
            </DropZoneLabelWrapper>
        }
        {
          isDragActive && isDragReject &&
            <DropZoneLabelWrapper>
              <DropZoneLabel>Wrong file type</DropZoneLabel>
            </DropZoneLabelWrapper>
        }
      </DropZoneHolder>
      <Modal show={showImageCropModal} close={() => setShowImageCropModal(false)} title="CROP IMAGE">
        <CropWrapper>
          <Cropper
            image={preview}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </CropWrapper>

<CropControl>

        <CropControl>
          <label>Rotation</label>
        <Spacer width="20"/>
          <input
            type="range"
            min="0"
            max="360"
            defaultValue={rotation}
            onChange={(e) => setRotation(e.target.value)}
          />
        </CropControl>
        <CropControl>
          <label>Zoom</label>
        <Spacer width="20"/>
          <input
            type="range"
            min="1"
            max="30"
            defaultValue={zoom}
            onChange={(e) => setZoom(e.target.value)}
          />
        </CropControl>
        </CropControl>


        <Button
          onClick={showCroppedImage}
        >
          Crop
        </Button>
      </Modal>
    </DropZoneWrapper>
  )
}

export default DropZone;