import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import Page from '../../components/Page'
import TabContainer, { Tab } from '../../components/TabContainer'
import Spacer from '../../components/Spacer'
import Input from '../../components/Input'
import Button from '../../components/Button'
import Toast from '../../components/Toast'
import DropZone, {allowedAudioFiles} from '../../components/DropZone'

import { requestPlaylistUpload, requestEditPlaylist } from '../../lib/mediations'

import { Text } from '../crews/CrewsNew'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 73px;
`

const FileUploadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const FileUploadItem = styled.div`
  display: flex;
  flex-direction: column;
`

const ButtonWrapper = styled.div`
  text-align: right;
`

const SevenKnotsMeditationUpload = () => {
  const history = useHistory()
  const [audioFile, setAudioFile] = useState(false)
  const $title = useRef(null)
  const $author = useRef(null)

  const fileUploadHandler = (image,file) => {
      setAudioFile(file)
  }

  const publish = async () => {
    if(!$title.current.value || !$author.current.value) {
      Toast.error("Please enter title and description")
    }

    if(!audioFile) {
      Toast.error("Please upload audio file")
    }

    try {
      const playlist = await requestPlaylistUpload(audioFile)

      await requestEditPlaylist(playlist.id, {
        name: $title.current.value,
        artist: $author.current.value
      })
    } catch (e) {
      Toast.error("Something went wrong")
      console.error(e)
      return
    }

    Toast.success('Song uploaded')

    history.push('/7knots/playlists')
  }

  return <Page>
    <TabContainer tabs={[
      <Tab type="blue" to="/7knots/meditations" >MEDITATIONS</Tab>,
      <Tab type="green" to="/7knots/playlists" data-active>UPLOAD</Tab>
    ]}>
      <Spacer height="60"/>
      <Wrapper>
        <Text>NEW SONG</Text>
        <Spacer height="30"/>
        <Input width="457px" ref={$title}/>
        <Spacer height="40"/>
        <Text>ARTiST</Text>
        <Spacer height="20"/>
        <Input width="457px" ref={$author}/>
        <Spacer height="35"/>
        <FileUploadWrapper>
          <FileUploadItem>
            <Text>ATTACH AUDIO</Text>
            <Spacer height="35"/>
            <DropZone
              items={[]}
              accept={allowedAudioFiles}
              uploadHandler={fileUploadHandler}
            />
          </FileUploadItem>
        </FileUploadWrapper>
        <Spacer height="10"/>
        <ButtonWrapper>
          <Button width="258px" onClick={publish}>SAVE</Button>
        </ButtonWrapper>
        <Spacer height="50"/>
      </Wrapper>
    </TabContainer>
  </Page>
}


export default SevenKnotsMeditationUpload