import { API, getToken } from '../constants/config'

export const requestMediations = async () => {
  const request = await fetch(`${API}/meditations`, {
      headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`
      },
  });

  return await request.json()
}


export const requestMediation = async (id) => {
  const request = await fetch(`${API}/meditations/${id}`, {
      headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`
      },
  });

  return await request.json()
}

export const requestFileUpload = async (id, type, file) => {
  const formData  = new FormData();
  formData.append('file', file);

  await fetch(`${API}/meditations/${id}/${type}`, {
      headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${getToken()}`
      },
      method: 'POST',
      body: formData
  });

  return true
}

export const requestCreateMeditation = async (meditation) => {
  const request = await fetch(`${API}/meditations`, {
      headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`
      },
      method: 'POST',
      body: JSON.stringify(meditation)
  });

  return await request.json()
}

export const requestDeleteMediation = async (id) => {
  await fetch(`${API}/meditations/${id}`, {
      headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`
      },
      method: 'DELETE',
  });

  return true;
}

export const requestEditMeditation = async (id,meditation) => {
  const request = await fetch(`${API}/meditations/${id}`, {
      headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`
      },
      method: 'PATCH',
      body: JSON.stringify(meditation)
  });

  return await request.json()
}

export const requestMeditationStatus = async (id, status) => {
  const command = status ? 'draft' : 'publish';

  await fetch(`${API}/meditations/${id}/${command}`, {
      headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`
      }
  });

  return true;
}

export const requestMeditationsPlaylist = async () => {
  const request = await fetch(`${API}/meditations/playlist`, {
      headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`
      },
  });

  return await request.json()
}

export const requestDeleteMeditationsPlaylist = async (id) => {
  await fetch(`${API}/media/${id}`, {
      headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`
      },
      method: 'DELETE',
  });

  return true
}

export const requestPlaylistUpload = async (file) => {
  const formData  = new FormData();
  formData.append('file', file);

  const request = await fetch(`${API}/meditations/playlist`, {
      headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${getToken()}`
      },
      method: 'POST',
      body: formData
  });

  return await request.json()
}

export const requestEditPlaylist = async (id,meditation) => {
  const request = await fetch(`${API}/meditations/playlist/${id}`, {
      headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`
      },
      method: 'PATCH',
      body: JSON.stringify(meditation)
  });

  return await request.json()
}