import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import Page from '../../components/Page'
import TabContainer, { Tab } from '../../components/TabContainer'
import Spacer from '../../components/Spacer'
import Input from '../../components/Input'
import TextArea from '../../components/TextArea'
import Button from '../../components/Button'
import Toast from '../../components/Toast'
import DropZone, {allowedAudioFiles, allowedImageFiles} from '../../components/DropZone'

import { requestCreateMeditation, requestFileUpload } from '../../lib/mediations'

import { Text } from '../crews/CrewsNew'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 73px;
`

const FileUploadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const FileUploadItem = styled.div`
  display: flex;
  flex-direction: column;
`

const ButtonWrapper = styled.div`
  text-align: right;
`

const SevenKnotsMeditationUpload = () => {
  const history = useHistory()
  const [audioFile, setAudioFile] = useState(false)
  const [imageFile, setImageFile] = useState(false)
  const $title = useRef(null)
  const $text = useRef(null)

  const fileUploadHandler = type => (image,file) => {
    if(type === 'image') {
      setImageFile(file)
    } else {
      setAudioFile(file)
    }
  }

  const publish = async () => {
    if(!$title.current.value || !$text.current.value) {
      Toast.error("Please enter title and description")
      return
    }

    if(!audioFile) {
      Toast.error("Please upload audio file for the meditation")
      return
    }

    try {
      const meditation = await requestCreateMeditation({
        title: $title.current.value,
        text: $text.current.value,
      })

      if(audioFile) {
        await requestFileUpload(meditation.id, "audio", audioFile)
      }

      if(imageFile) {
        await requestFileUpload(meditation.id, "image", imageFile)
      }


    } catch (e) {
      Toast.error("Something went wrong")

      return
    }

    Toast.success('Meditation Created')

    history.push('/7knots/meditations')
  }

  return <Page>
    <TabContainer tabs={[
      <Tab type="blue" to="/7knots/meditations" >MEDITATIONS</Tab>,
      <Tab type="green" to="/7knots/playlists" data-active>UPLOAD</Tab>
    ]}>
      <Spacer height="60"/>
      <Wrapper>
        <Text>TITLE</Text>
        <Spacer height="30"/>
        <Input width="457px" ref={$title}/>
        <Spacer height="40"/>
        <Text>DESCRIPTION</Text>
        <Spacer height="20"/>
        <TextArea ref={$text} rows="5"/>
        <Spacer height="35"/>
        <FileUploadWrapper>
          <FileUploadItem>
            <Text>ATTACH IMAGE</Text>
            <Spacer height="35"/>
            <DropZone
              items={[]}
              accept={allowedImageFiles}
              uploadHandler={fileUploadHandler("image")}
            />
          </FileUploadItem>
          <FileUploadItem>
            <Text>ATTACH AUDIO</Text>
            <Spacer height="35"/>
            <DropZone
              items={[]}
              accept={allowedAudioFiles}
              uploadHandler={fileUploadHandler("audio")}
            />
          </FileUploadItem>
        </FileUploadWrapper>
        <Spacer height="127"/>
        <ButtonWrapper>
          <Button width="258px" onClick={publish}>PUBLISH</Button>
        </ButtonWrapper>
        <Spacer height="50"/>
      </Wrapper>
    </TabContainer>
  </Page>
}


export default SevenKnotsMeditationUpload