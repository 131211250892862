import jwt from 'jsonwebtoken';

import Auth from '../services/auth';

export const AUTHORIZE  = 'AUTHORIZE'
export const RENEW  = 'RENEW'
export const EMAIL_RESET  = 'EMAIL_RESET'
export const LOGOUT  = 'LOGOUT'

export const authorize = ({
  username,
  password,
}) => async (dispatch) => {
  let authRequest = await Auth.login({
    username,
    password,
  })

  if(authRequest.err) {
    throw new Error(authRequest.err.description)
  }

  const token = jwt.decode(authRequest.authResult.accessToken)

  if(token.permissions.indexOf("roles:admin") !== -1) {
    dispatch({
      type: AUTHORIZE,
      payload: {
        accessToken: authRequest.authResult.accessToken,
        expiresAt: token.exp * 1000
      }
    })
  } else {
    throw new Error('permission')
  }
}

export const renewSession = () => (dispatch) => {
  Auth.renewSession().then(({err,authResult}) => {
    if(err) {
      throw new Error(err)
    } else {
      dispatch({
        type: RENEW,
        payload: authResult
      })
    }
  });
}

export const askForResetPasswordEmail = (email) => async (dispatch, getState) => {
  let authRequest = await Auth.changePassword(email);

  if(authRequest === null) {
    return dispatch({
      type: EMAIL_RESET,
    })
  }

  throw new Error(authRequest.err.description)
}

export const logout = () => (dispatch, getState) => {
  dispatch({
    type: LOGOUT
  });

  Auth.logout();
}

export default function(state = {
  fetching: false,
  accessToken: null,
  idToken: null,
  error: null,
  expiresAt: null,
  authorized: false,
  passwordReseted: false,
  signup: false
}, action) {
  switch (action.type) {
    case AUTHORIZE:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        expiresAt: action.payload.expiresAt,
        authorized: !(action.payload.expiresAt < Date.now())
      };
    case RENEW:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        idToken: action.payload.idToken,
        expiresAt: action.payload.idTokenPayload.exp * 1000,
        authorized: !(action.payload.idTokenPayload.exp * 1000 < Date.now())
      }
    case LOGOUT:
        return {
          ...state,
          accessToken: null,
          idToken: null,
          expiresAt: null,
          authorized: false,
        }
    case EMAIL_RESET:
      return {
        ...state,
        passwordReseted: true
      }
    default:
      return state;
  }
}