import React, { useEffect, useState, useMemo, useRef } from 'react'
import styled, { css } from 'styled-components'
import { useImmer } from 'use-immer'
import { Link, useHistory, useLocation } from 'react-router-dom'

import generateMediaURL from '../utils/generateMediaURL';

import Button from './Button'
import Spacer from './Spacer'
import Icon from './Icon'
import Modal from './Modal'
import TextArea from './TextArea'
import Toast from './Toast'
import Input from './Input'
import DropZone, { allowedAudioFiles, allowedImageFiles } from './DropZone'

import {
    requestDeleteMediation,
    requestFileUpload,
    requestEditMeditation,
    requestMeditationStatus,
  } from '../lib/mediations'

import ArrowDown from '../assets/images/arrow-down.svg'
import ArrowUp from '../assets/images/arrow-up.svg'

import { Text } from '../pages/crews/CrewsNew'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 45px;
  border: 2px solid #DFE5EE;
  box-sizing: border-box;
  border-radius: 30px;
  flex-direction: column;

  ${props => props.$isExpanded && css`
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}
`
const Holder = styled.div`
  width: 100%;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ButtonWrapper = styled.div`
  z-index: ${props => props.$show ? 1 : -1};
  display: flex;
`

const AccordeonToggle = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 2.88889px;
  text-transform: uppercase;
  color: #6C7B8A;
  max-width: 200px;
`

const AccordeonButton = styled.img`
  cursor: pointer;
`

const Content = styled.div`
  width: 100%;
`

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
`

const Description = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
  color: #000000;
`

const DeleteWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Player = styled.div`
  background-color: #353A40;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 20px 50px 30px 50px;
`

const PlayerTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const PlayerTime = styled.span`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
`

const ProgressBar = styled.div`
  height: 5px;
  background-color: #6C7B8A;
  border-radius: 10px;
  cursor: pointer;
`

const Progress = styled.div`
  height: 5px;
  background: linear-gradient(150.03deg, #45E994 -12.54%, #23BCBA 108.63%);
  border-radius: 10px;
  width: ${props => props.$progress}%;
`

const PlayerButton = styled.div`
  cursor: pointer;
  background: #FFFFFF;
  border-radius: 100%;
  padding: ${props => props.$padding};
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

const PlayerFooter = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

const PlayerControls = styled.div`
  display: flex;
  align-items: center;
`

const ButtonControls = styled.div`
  display: flex;
`

const ModalButton = styled.div`
  text-align: center;
`

function getTimeCodeFromNum(num) {
  let seconds = parseInt(num);
  let minutes = parseInt(seconds / 60);
  seconds -= minutes * 60;
  const hours = parseInt(minutes / 60);
  minutes -= hours * 60;

  if (hours === 0) return `${minutes}:${String(seconds % 60).padStart(2, 0)}`;
  return `${String(hours).padStart(2, 0)}:${minutes}:${String(
    seconds % 60
  ).padStart(2, 0)}`;
}

const Meditation = ({
  meditation,
  edit
}) => {
  const history = useHistory()
  const location = useLocation()
  const $text = useRef(null)
  const $title = useRef(null)
  const [isExpanded, setIsExpanded] = useState(edit)
  const [isDeleted, setIsDeleted] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showNewAudioModal, setShowNewAudioModal] = useState(false)
  const [showNewImageModal, setShowNewImageModal] = useState(false)
  const [showConfirmNewAudioModal, setShowConfirmNewAudioModal] = useState(false)
  const [newAudioFile, setNewAudioFile] = useState(false)
  const [newImageFile, setNewImageFile] = useState(false)
  const [showActionModal, setShowActionModal] = useState(false)
  const [playerState, setPlayerState] = useImmer({
    isPlaying: false,
    progress: 0,
    current: getTimeCodeFromNum(0)
  })

  const ArrowImage = isExpanded ? ArrowUp : ArrowDown;

  const toggle = () => {
    setIsExpanded(!isExpanded)
  }

  const audioFile = meditation?.linkedMedia?.find(media => media.type === 'audio')


  const audio = useMemo(() => audioFile ? new Audio(generateMediaURL(audioFile)) : false, [audioFile])

  useEffect(() => {
    return () => {
      if(audio) {
        audio.pause()
      }
    }
  }, [audio])

  if(audio) {
    audio.addEventListener(
      "loadeddata",
      () => {
        setPlayerState(player => {player.total = getTimeCodeFromNum(
          audio.duration
        )})
        audio.volume = .75;
      },
      false
    );

    audio.addEventListener(
      "ended",
      () => {
        setPlayerState(player => {
          player.isPlaying = false
          player.progress = 100
        })
      },
      false
    );
  }

  const handleTimelineClick = (e) => {
    const { duration} = audio
    const timelineWidth = window.getComputedStyle(e.target).width;
    const timeToSeek = e.nativeEvent.offsetX / window.parseInt(timelineWidth) * duration;
    audio.currentTime = timeToSeek;

    setPlayerState(player => {
      player.progress = timeToSeek / duration * 100
    })
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if(playerState.isPlaying) {
        const {currentTime, duration} = audio

        setPlayerState(player => {
          player.progress = currentTime / duration * 100
          player.current = getTimeCodeFromNum(
            audio.currentTime
          );
        })
      }

    }, 500);

    return () => clearInterval(interval);
  }, [audio, setPlayerState, playerState.isPlaying]);

  const togglePlayback = () => {
    if(playerState.isPlaying) {
      audio.pause()
      setPlayerState(player => {player.isPlaying = false})
    } else {
      audio.play()
      setPlayerState(player => {player.isPlaying = true})
    }
  }

  useEffect(() => {
    if(!isExpanded && audio) {
      audio.pause();
      setPlayerState(player => {player.isPlaying = false})
    }
  }, [isExpanded, audio, setPlayerState])

  const deleteMeditation = async () => {
    await requestDeleteMediation(meditation.id)
    setIsDeleted(true)
  }

  const audioUploadHandler = (image,file) => {
    setNewAudioFile(file)
  }

  const imageUploadHandler = (image,file) => {
    setNewImageFile(file)
  }

  const audioUploadProceed = (image,file) => {
    setShowNewAudioModal(false)
    setShowConfirmNewAudioModal(true)
  }

  const updateMeditation = async () => {
    await requestEditMeditation(meditation.id, {
      title: $title.current.value,
      text: $text.current.value,
    })

    history.replace(location.pathname)

    Toast.success("Meditation updated")
  }

  const saveNewFile = (type) => async () => {
    const file = type === 'audio' ? newAudioFile : newImageFile;

    await requestFileUpload(meditation.id, type, file)
    history.replace(location.pathname)

    Toast.success(`New ${type} saved`)
  }
  const changeMeditationStatus = async () => {
    await requestMeditationStatus(meditation.id, meditation.published)
    setShowActionModal(false)
    history.replace(location.pathname)

    Toast.success(`Meditaion was ${meditation.published ? 'drafted' : 'published'}`)
  }

  if(isDeleted) {
    return null
  }

  return (
      <Holder>
        <Wrapper $isExpanded={isExpanded && audio}>
            <Header>
              {!edit && <Title>{meditation.title}</Title>}
              {edit && <InputGroup>
                <Text>TItle</Text>
                <Spacer height="20"/>
                <Input defaultValue={meditation.title} ref={$title}/>
              </InputGroup>}
              <AccordeonToggle>
                <ButtonWrapper $show={!isExpanded}>
                  <Link to={`/7knots/edit/${meditation.id}`}><Button width="287px">Edit</Button></Link>
                  <Spacer width="15"/>
                  <Button type="white" width="140px" onClick={() => setShowActionModal(true)}>{meditation.published ? 'DRAFT' : 'PUBLISH'}</Button>
                </ButtonWrapper>
                <Spacer width="55"/>
                {!edit && <AccordeonButton onClick={toggle} src={ArrowImage} alt='toggle'/>}
              </AccordeonToggle>
            </Header>
            {isExpanded && (
              <Content>
                {!edit && (
                  <>
                    <Spacer height="50"/>
                    <Description>
                      {meditation.text}
                    </Description>
                  </>
                )}
                {edit && <InputGroup>
                  <Spacer height="25"/>
                  <Text>Description</Text>
                  <Spacer height="20"/>
                  <TextArea defaultValue={meditation.text} ref={$text}/>
                </InputGroup>
                }
                {!edit && (
                  <>
                    <Spacer height="30"/>
                    <DeleteWrapper>
                      {!audio && <Link to={`/7knots/edit/${meditation.id}`}><Button width="287px">Edit</Button></Link>}
                      <Spacer width="15"/>
                      <Button width="287px" type="white" onClick={() => setShowDeleteModal(true)}>DELETE</Button>
                    </DeleteWrapper>
                  </>
                )}
              </Content>
            )}
        </Wrapper>
        {isExpanded && audio && (
          <Player>
              <PlayerTop>
              <PlayerTime>{playerState.current}</PlayerTime>
              <PlayerTime>{playerState.total}</PlayerTime>
              </PlayerTop>
              <Spacer height="20"/>
              <ProgressBar onClick={handleTimelineClick}>
                <Progress $progress={playerState.progress}/>
              </ProgressBar>
              <Spacer height="32"/>
              <PlayerFooter>
                <PlayerControls>
                  <PlayerButton $padding="10px" onClick={togglePlayback}><Icon size="47.5px" color="#353A40" icon={playerState.isPlaying ? "mdiPause" : "mdiPlay"}/></PlayerButton>
                </PlayerControls>
                <Spacer width="30"/>
                {!edit && (
                  <ButtonControls>
                    <Link to={`/7knots/edit/${meditation.id}`}><Button width="287px">Edit</Button></Link>
                  </ButtonControls>
                )}
                {edit && (
                  <ButtonControls>
                    <Button width="220px" type="white" onClick={() => setShowNewAudioModal(true)}>NEW AUDIO</Button>
                    <Spacer width="15"/>
                    <Button width="220px" type="white" onClick={() => setShowNewImageModal(true)}>NEW Image</Button>
                    <Spacer width="15"/>
                    <Button width="220px" onClick={updateMeditation}>SAVE</Button>
                  </ButtonControls>
                )}
              </PlayerFooter>
          </Player>
        )}
        <Modal show={showDeleteModal} close={() => setShowDeleteModal(false)} title="DELETE MEDITATION">
          <Spacer height="20"/>
          <span>
            Are you sure you want to delete <b>{meditation.title}</b>?<br/><br/>
            It will be permanently removed.
          </span>
          <Spacer height="82"/>
          <ModalButton>
            <Button width="287px" onClick={deleteMeditation}>DELETE</Button>
          </ModalButton>
        </Modal>
        <Modal show={showNewAudioModal} close={() => setShowNewAudioModal(false)} title="UPLOAD NEW FILE">
          <Spacer height="20"/>
          <DropZone
            fullwidth
            items={[]}
            accept={allowedAudioFiles}
            uploadHandler={audioUploadHandler}
          />
          <Spacer height="82"/>
          <ModalButton>
            <Button width="287px" onClick={audioUploadProceed} disabled={!newAudioFile}>Save</Button>
          </ModalButton>
        </Modal>
        <Modal show={showConfirmNewAudioModal} close={() => setShowConfirmNewAudioModal(false)} title="UPLOAD NEW FILE">
          <Spacer height="20"/>
          <span>
            {audio && <>Are you sure you want to replace <b>{audioFile.meta.originalname}</b> with <b>{newAudioFile.name}</b>?</>}
            {!audio && <>Are you sure you want upload <b>{newAudioFile.name}</b>?</>}

          </span>
          <br></br>
          {audio && <span>
            <b>{audioFile.meta.originalname}</b> will be permanently removed.
          </span>}
          <Spacer height="82"/>
          <ModalButton>
            <Button width="287px" onClick={saveNewFile('audio')}>CONTINUE</Button>
          </ModalButton>
        </Modal>
        <Modal show={showActionModal} close={() => setShowActionModal(false)} title={`${meditation.published ? 'DRAFT' : 'PUBLISH'} MEDITATION`}>
          <Spacer height="20"/>
          <span>
              Are you sure you want to {meditation.published ? 'draft' : 'publish'} <b>{meditation.title}</b>?<br/><br/>
            </span>
          <Spacer height="50"/>
          <ModalButton>
            <Button width="287px" onClick={changeMeditationStatus}>{meditation.published ? 'DRAFT' : 'PUBLISH'}</Button>
          </ModalButton>
        </Modal>
        <Modal show={showNewImageModal} close={() => setShowNewImageModal(false)} title="UPLOAD NEW IMAGE">
          <Spacer height="20"/>
          <DropZone
            fullwidth
            items={[]}
            accept={allowedImageFiles}
            uploadHandler={imageUploadHandler}
          />
          <Spacer height="82"/>
          <ModalButton>
            <Button width="287px" onClick={saveNewFile('image')} disabled={!newImageFile}>Save</Button>
          </ModalButton>
        </Modal>
    </Holder>
  )
}


export default Meditation