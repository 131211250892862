import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, RichUtils } from 'draft-js'

import Button from './Button'
import Spacer from './Spacer'
import Input from './Input'
import TextArea from './TextArea'
import DropZone, { allowedImageFiles } from './DropZone'

import EditorHeadingImage from '../assets/images/editor-heading.svg'
import EditorItalicImage from '../assets/images/editor-italic.svg'
import EditorBoldImage from '../assets/images/editor-bold.svg'
import EditorImageImage from '../assets/images/editor-image.svg'
import EditorUnderlineImage from '../assets/images/editor-underline.svg'
import EditorLinkImage from '../assets/images/editor-link.svg'

import { Text } from '../pages/crews/CrewsNew'

const Wrapper = styled.div`
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const EditorTextWrapper = styled.div`
  position: relative;
  top: 30px;
  width: 50%;
`;

const Heading = ({editorState, onChange, ...props}) => {
  const toggleBold = () => {
    const newState = RichUtils.toggleBlockType(
      editorState,
      'header-two',
    );

    if (newState) {
      onChange(newState);
    }
  };

  return (
    <div className="rdw-inline-wrapper">
      <div className={`rdw-option-wrapper`}>
        <img className="rdw-storybook-custom-option" src={EditorHeadingImage} onClick={toggleBold} alt="heading"/>
      </div>
    </div>
  );
}

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

const PostInput = ({
  buttonText,
  submit,
  initalEditorState,
  postImage = false,
  title,
  subTitle
}) => {
  const [editorState, setEditorState] = useState(initalEditorState || EditorState.createEmpty())
  const [imageFile, setImageFile] = useState(false)
  const $title = useRef(null);
  const $subTitle = useRef(null);


  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  const uploadImageCallBack = (file) => {
    return new Promise(
      async (resolve, reject) => {
        const base64 = await toBase64(file)

        resolve({ data: { link: base64}})
      },
    );
  }

  const coverUploadHandler = (image,file) => {
    setImageFile(file)
  }

  const handleCTA = () => {
    submit({
      $title,
      $subTitle,
      editorState,
      imageFile
    })
  }

  return (
    <Wrapper>
      <Text>POST TITLE</Text>
      <Spacer height="20"/>
      <Input width="457px" ref={$title} defaultValue={title}/>
      <Spacer height="30"/>
      <Text>SUBTITLE</Text>
      <Spacer height="20"/>
      <TextArea ref={$subTitle} defaultValue={subTitle}/>
      <Spacer height="10"/>
      <EditorTextWrapper>
        <Text>Body</Text>
      </EditorTextWrapper>
      <Editor
        toolbarCustomButtons={[<Heading />]}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        toolbar={{
          options: ['colorPicker', 'inline', 'link', 'image'].reverse(),
          inline: {
            inDropdown: false,
            options: ['bold', 'italic'],
            underline: { icon: EditorUnderlineImage, className: undefined },
            bold: { icon: EditorBoldImage, className: undefined },
            italic: { icon: EditorItalicImage, className: undefined },
          },
          colorPicker: {
            icon: EditorUnderlineImage,
            colors: [
              '#001f3f',
              '#0074D9',
              '#7FDBFF',
              '#39CCCC',
              '#3D9970',
              '#2ECC40',
              '#01FF70',
              '#FFDC00',
              '#FF851B',
              '#FF4136',
              '#85144b',
              '#F012BE',
              '#B10DC9',
              '#111111',
              '#AAAAAA',
              '#DDDDDD',
            ],
          },
          link: {
            inDropdown: false,
            defaultTargetOption: '_blank',
            options: ['link', 'unlink'],
            link: { icon: EditorLinkImage, className: undefined },
            unlink: { icon: EditorLinkImage, className: undefined },
            linkCallback: undefined
          },
          image: {
            icon: EditorImageImage,
            urlEnabled: true,
            uploadEnabled: true,
            alignmentEnabled: true,
            previewImage: true,
            uploadCallback: uploadImageCallBack,
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            alt: { present: false, mandatory: false },
          },
        }}
      />
      <Spacer height="37"/>
      <Text>Upload Cover IMAGE</Text>
      <Spacer height="20"/>
      <ButtonWrapper>
        <DropZone
          items={[]}
          accept={allowedImageFiles}
          uploadHandler={coverUploadHandler}
          image={postImage}
        />
        <Spacer width="94"/>
        <Button width="287px" onClick={handleCTA}>{buttonText}</Button>
      </ButtonWrapper>
    </Wrapper>
  )
}


export default PostInput