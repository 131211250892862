import produce from "immer"

import { requestCrews } from '../lib/crews';

const CREWS  = 'CREWS'

export const getCrews = () => async (dispatch) => {
  const crews = await requestCrews()

  dispatch({
    type: CREWS,
    payload: crews
  })
}

export default produce((draft, action) => {
  switch (action.type) {
    case CREWS:
      draft.crews = action.payload
      break;
    default:
      break;
  }
}, {
  crews: []
})