import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Page from '../../components/Page'
import TabContainer, { Tab } from '../../components/TabContainer'
import Button from '../../components/Button'
import Spacer from '../../components/Spacer'
import CrewTable from '../../components/CrewTable'
import NoData from '../../components/NoData'

import { getCrews } from '../../ducks/crews'
import selectCrews from '../../selectors/selectCrews'

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const CrewsAll = () => {
  const crews = useSelector(selectCrews)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCrews())
  }, [dispatch])

  return <Page>
    <TabContainer tabs={[
      <Tab type="red" to="#" data-active>ALL CREWS</Tab>,
      <Tab type="blue" to="/crews/all-members">ALL MEMBERS</Tab>,
      <Tab type="green" to="/crews/unassigned">UNASSIGNED</Tab>
    ]}>
      <ButtonWrapper>
        <Link to="/crews/new">
          <Button width="287px">+ NEW CREW</Button>
        </Link>
      </ButtonWrapper>
      <Spacer height="50"/>
      {crews.crews.length > 0 && <CrewTable crews={crews.crews}/>}
      {crews.crews.length === 0 && <NoData/>}
    </TabContainer>
  </Page>
}


export default CrewsAll