import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { convertToRaw, EditorState, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'

import { requestPost, requestImageUpload, requestEditPost } from '../../lib/posts'

import generateMediaURL from '../../utils/generateMediaURL';

import Page from '../../components/Page'
import TabContainer, { Tab } from '../../components/TabContainer'
import PostInput from '../../components/PostInput'
import Toast from '../../components/Toast'

const Wrapper = styled.div`
  padding: 50px 176px 233px 128px;
`

const PostEdit = () => {
  const history = useHistory()
  const [post, setPost] = useState(false)
  const [initalEditorState, setInitialEditorState] = useState(false)
  const params = useParams()

  useEffect(() => {
    const fetchPosts = async () => {
      const postResponse = await requestPost(params.id)

      const contentBlock = htmlToDraft(postResponse.content);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);

      setPost(postResponse)
      setInitialEditorState(editorState)
    }

    fetchPosts()
  }, [])

  if(!post || !initalEditorState) {
    return null
  }

  const editPost = async ({
    $title,
    $subTitle,
    editorState,
    imageFile
  }) => {
    if(!$title.current.value || !$subTitle.current.value) {
      Toast.error("Please enter title and subtitle")
      return;
    }
    const rawContentState = convertToRaw(editorState.getCurrentContent());

    const markup = draftToHtml(
      rawContentState
    );

    try {
      const editPostRequest = await requestEditPost(post.id, {
        title: $title.current.value,
        subTitle: $subTitle.current.value,
        content: markup
      })

      if(editPostRequest.statusCode === 400) {
        throw new Error();
      }
    } catch (e) {
      Toast.error("Something went wrong while editing post")
      return
    }

    try {
      if(imageFile) {
        await requestImageUpload(post.id, imageFile)
      }
    } catch (e) {
      Toast.error("Something went wrong while uploading image")
      return
    }

    history.push(`/posts/view/${post.id}`)
  }

  return <Page>
    <TabContainer tabs={[
      <Tab type="blue" to="/posts/new" >NEW POST</Tab>,
      <Tab type="green"to="/posts/feed" data-active>FEED</Tab>
    ]}>
      <Wrapper>
        <PostInput
          buttonText="SAVE"
          title={post.title}
          subTitle={post.subTitle}
          submit={editPost}
          initalEditorState={initalEditorState}
          postImage={generateMediaURL(post?.posterImage)}
        />
      </Wrapper>
    </TabContainer>
  </Page>
}


export default PostEdit