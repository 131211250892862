import React from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import CheckboxDefaultImage from '../assets/images/checkbox-default.svg'
import CheckboxCheckedImage from '../assets/images/checkbox-checked.svg'

const CheckboxWrapper = styled.div`
  [type=checkbox]:not(:checked),
  [type=checkbox]:checked {
    position: absolute;
    left: -9999px;
  }

  [type=checkbox]:not(:checked) + label,
  [type=checkbox]:checked + label {
    position: relative;
    padding-left: 0;
    cursor: pointer;
  }

  [type=checkbox]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: -7px;
    width: 31px;
    height: 31px;
    background: url(${CheckboxDefaultImage});
    background-repeat: no-repeat;
  }

  [type=checkbox]:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: -7px;
    width: 31px;
    height: 31px;
    background: url(${CheckboxCheckedImage});
    background-repeat: no-repeat;
  }

  [type=checkbox]:not(:checked) + label:after,
  [type=checkbox]:checked + label:after {
    content: ' ';
    position: absolute;
    top: .15em;
    left: .22em;
    font-size: 1.3em;
    line-height: 0.8;
    color: #09ad7e;
    transition: all .2s;
    font-family: 'Lucida Sans Unicode', 'Arial Unicode MS', Arial;
  }
`

const Checkbox = ({...props}) => {
  const id = uuid();

  return (
    <CheckboxWrapper>
      <input type="checkbox" {...props} id={id}/>
      <label htmlFor={id}></label>
    </CheckboxWrapper>
  )
}

export default Checkbox