import {
  AUTHORIZE,
  RENEW,
  LOGOUT
} from '../ducks/authorization';

export default ({ getState, dispatch }) => next => (action) => {
  const result = next(action);

  switch (action.type) {
    case AUTHORIZE: {
        localStorage.setItem('sessionData', JSON.stringify(
          {
            authResult: action.payload,
          }
        ))
      break;
    }
    case RENEW: {
      const currentSession = JSON.parse(localStorage.getItem('sessionData')) || {};

      localStorage.setItem('sessionData', JSON.stringify({
        ...currentSession,
        ...{
          authResult: {
            accessToken: action.payload.accessToken,
            idToken: action.payload.idToken,
          },
          expiresAt: action.payload.idTokenPayload.exp * 1000,
        }
      }));
      break;
    }
    case LOGOUT:
        localStorage.setItem('sessionData', null)
      break;
    default:
     return result;
  }

  return result;

};
