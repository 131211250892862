import React, {Fragment} from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { v4 as uuid } from 'uuid';

const TabContainerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const TabContent = styled.div`
  background: #FFFFFF;
  border-top-left-radius: 51px;
  border-top-right-radius: 51px;
  padding: 50px 0 0 0;
  flex: 1;
  overflow: scroll;
`;

const Tabs = styled.div`
  display: flex;
  margin-left: 70px;
`

export const Tab = styled(Link)`
  background: #FFFFFF;
  border-radius: 13px 13px 0px 0px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 2.88889px;
  text-transform: uppercase;
  color: #000000;
  padding: 24px 0;
  width: 235px;
  text-decoration: none;
  text-align: center;
  ${props => {
    switch(props.type) {
      case "red":
          return css`background: linear-gradient(154.75deg, #FFC387 -2.44%, #FF6A61 107.72%);`;
      case "blue":
          return css`background: linear-gradient(168.53deg, #17EAD9 -21.94%, #6078EA 133.81%);`;
      case "green":
          return css`background: linear-gradient(150.03deg, #45E994 -12.54%, #23BCBA 108.63%);`;
      default:
          return css`background: linear-gradient(168.53deg, #17EAD9 -21.94%, #6078EA 133.81%);`;
    }
  }}

  &[data-active] {
    background: #FFFFFF !important;
  }

  margin-left: 30px;
`

const TabContainer = ({tabs, children}) => {
  return (
    <TabContainerWrapper>
      <Tabs>
        {tabs.map(tab => <Fragment key={uuid()}>{tab}</Fragment>)}
      </Tabs>
      <TabContent>{children}</TabContent>
    </TabContainerWrapper>
  )
}


export default TabContainer