import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import Page from '../../components/Page'
import TabContainer, { Tab } from '../../components/TabContainer'
import Spacer from '../../components/Spacer'
import Meditation from '../../components/Meditation'
import NoData from '../../components/NoData'

import { requestMediations } from '../../lib/mediations'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 73px;
  flex-direction: column;

  & > * {
    margin: 16px 0;

    &:first-child {
      margin-top: 0;
    }

    &:first-child {
      margin-bottom: 0;
    }
  }
`

const SevenKnotsMeditations = () => {
  const [mediations, setMediations] = useState(false)

  useEffect(() => {
    const fetchMediations = async () => {
      const mediationsResponse = await requestMediations();

      setMediations(mediationsResponse)
    }

    fetchMediations()
  }, [])

  if(mediations.length === 0) {
    return null
  }

  return <Page>
    <TabContainer tabs={[
      <Tab type="blue" to="#" data-active>MEDITATIONS</Tab>,
      <Tab type="green" to="/7knots/playlists">UPLOAD</Tab>
    ]}>
      {mediations &&
      <>
        <Spacer height="60"/>
        <Wrapper>
          {mediations.map(mediation => <Meditation key={mediation.id} meditation={mediation}/>)}
        </Wrapper>
      </>}
      {!mediations && <NoData/>}
    </TabContainer>
  </Page>
}


export default SevenKnotsMeditations