import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { authorize, askForResetPasswordEmail} from '../../ducks/authorization'

import Spacer from '../../components/Spacer'
import HR from '../../components/HR'
import Input from '../../components/Input'
import Button from '../../components/Button'
import Toast from '../../components/Toast'

import Logo from '../../assets/images/logo.svg'
import TribeHouseLogo from '../../assets/images/tribe-house-logo.svg'

import {
  Wrapper,
  LoginWrapper,
  LogoText,
  LoginForm,
  ForgotLink,
  FooterText,
  ButtonWrapper
} from './Login.styled.js'

const Login = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const login = async () => {
    try {
      await dispatch(authorize({
        username: $username.current.value,
        password: $password.current.value,
      }))

      history.push('/crews/all')
    } catch (e) {
      if(e.message === "permission") {
        Toast.error("Invalid account")
      } else {
        Toast.error(e.message)
      }
    }
  }

  const resetPassword = async () => {
    if(!$username.current.value) {
      Toast.warning("Please enter your email")
    } else {
      try {
        await dispatch(askForResetPasswordEmail($username.current.value));

        Toast.success("Reset Password email sent");
      } catch (e) {
        Toast.error("Something went wrong")
      }
    }
  }

  const $username = useRef(null);
  const $password = useRef(null);

  return (
    <Wrapper>
      <LoginWrapper>
        <img src={Logo} alt="logo"/>
        <Spacer height="22"/>
        <LogoText>FIRE TRIBES</LogoText>
        <Spacer height="30"/>
        <HR width="130"/>
        <Spacer height="75"/>
        <LoginForm>
          <Input type="email" placeholder="Email" ref={$username}/>
          <Spacer height="16.5"/>
          <Input type="password" placeholder="Password" ref={$password}/>
          <Spacer height="13"/>
          <ForgotLink onClick={resetPassword}>Forgot Password?</ForgotLink>
          <Spacer height="60"/>
          <ButtonWrapper>
            <Button width="287px" onClick={login}>Log In</Button>
          </ButtonWrapper>
        </LoginForm>
      </LoginWrapper>

      <div>
        <Spacer height="200"/>
        <FooterText>powered by</FooterText>
        <img src={TribeHouseLogo} alt="logo"/>
      </div>
      <Spacer height="50"/>
    </Wrapper>
  )
}

export default Login