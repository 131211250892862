import { API, getToken } from '../constants/config'

export const requestCreatePost = async (post) => {
  const request = await fetch(`${API}/posts`, {
      headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`
      },
      method: 'POST',
      body: JSON.stringify(post)
  });

  return await request.json()
}

export const requestPosts = async (q = '') => {
  const request = await fetch(`${API}/posts${(q && q.length > 3) ? `?query=${q}` : ''}`, {
      headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`
      },
  });

  return await request.json()
}

export const requestPost = async (id) => {
  const request = await fetch(`${API}/posts/${id}`, {
      headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`
      },
  });

  return await request.json()
}

export const requestImageUpload = async (id, file) => {
  const formData  = new FormData();
  formData.append('file', file);

  const request = await fetch(`${API}/posts/${id}/image`, {
      headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${getToken()}`
      },
      method: 'POST',
      body: formData
  });

  const requestJson = await request.json();

  return requestJson.posterImage.absolutePath
}

export const requestEditPost = async (id,post) => {
  const request = await fetch(`${API}/posts/${id}`, {
      headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`
      },
      method: 'PATCH',
      body: JSON.stringify(post)
  });

  return await request.json()
}

export const requestDeletePost = async (id) => {
  await fetch(`${API}/posts/${id}`, {
      headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`
      },
      method: 'DELETE',
  });

  return true;
}

export const requestPostStatus = async (id, status) => {
  const command = status ? 'publish' : 'unpublish';

  await fetch(`${API}/posts/${id}/${command}`, {
      headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`
      }
  });

  return true;
}