import React from 'react'
import MDIcon from '@mdi/react';
import * as mdi from '@mdi/js'

const Icon = ({icon, size, color = "white", ...props }) => {
  return <MDIcon
    path={mdi[icon]}
    size={size}
    color={color}
    {...props}
  />
}

export default Icon