import styled, {css} from 'styled-components'

export default styled.input`
  background: rgba(20, 15, 38, 0.5);
  backdrop-filter: blur(27.1828px);
  border-radius: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  padding: 15px 25px;
  outline: 0;
  border: 0;
  width: ${props => props.width ? `${props.width}` : 'initial'};

  &::placeholder {
    color: #DDD;
    opacity: 1;
  }

  ${props => props.icon && css`
    background-image: url(${props.icon});
    background-repeat: no-repeat;
    background-position-x: 20px;
    background-position-y: center;
    padding-left: 60px;
  `}
`