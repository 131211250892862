const CDN_URL = 'https://d252rknkjrx92x.cloudfront.net/'

const generateMediaURL = (media) => {
  if(!media) {
    return false
  }

  return CDN_URL + media.path
}

export default generateMediaURL