import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 25px 40px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 20px;
  letter-spacing: 2.88889px;
  text-transform: uppercase;
  color: #000;
`;

const NoData = () => {
  return <Wrapper>
    No data available
  </Wrapper>
}

export default NoData;
