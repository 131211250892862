import React, { useEffect } from 'react'
import { useImmer } from 'use-immer'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import { requestUnnasigned, requestUpdateCrew, requestCrews } from '../../lib/crews'

import Page from '../../components/Page'
import TabContainer, { Tab } from '../../components/TabContainer'
import Spacer from '../../components/Spacer'
import Button from '../../components/Button'
import Checkbox from '../../components/Checkbox'
import Modal from '../../components/Modal'

import {
  PersonData,
  Name,
  Company,
  CrewCount,
  CrewTitle,
  AssignTable,
} from '../../components/CrewTable'
import Toast from '../../components/Toast'

const Text = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
  letter-spacing: 2.88889px;
  text-transform: uppercase;
  color: #000000;
`

const Wrapper = styled.div`
  padding-left: 90px;
  padding-right: 70px;
`

const AssignRow = styled.div`
  padding: 33px 0 33px 97px;
  display: flex;
  border-top: 1px solid #DFE5EE;

  &:last-child {
    border-bottom: 1px solid #DFE5EE;
  }
`

const ButtonWrapper = styled.div`
  text-align: right;
  padding-right: 36px;
`

const ModalButtonWrapper = styled.div`
  text-align: center;
`

const CrewRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 20px;
`

const CrewData = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

const CrewsUnassigned = () => {
  const history = useHistory()
  const [users, setUsers] = useImmer([])
  const [crews, setCrews] = useImmer([])
  const [newMembers, setNewMembers] = useImmer([])
  const [newCrews, setNewCrews] = useImmer([])
  const [showCrewsModal, setShowCrewsModal] = useImmer(false)
  const [isFetching, setIsFetching] = useImmer(false)


  useEffect(() => {
    const fetchUsers = async () => {
      const usersRequest = await requestUnnasigned();

      setUsers(draft => usersRequest)
    }

    const fetchCrews = async () => {
      const crewsRequest = await requestCrews();

      setCrews(draft => crewsRequest)
    }

    fetchUsers()
    fetchCrews()
  }, [])

  if(users.length === 0 || crews.length === 0) {
    return null
  }

  const handleAssigneeChange = (e, id) => {
    if(e.target.checked) {
      setNewMembers(draft => {
        draft.push(id)
      })
    } else {
      setNewMembers(draft => {
        draft = draft.filter(_id => _id !== id)
      })
    }
  }

  const handleCrewChange = (e, crew) => {
    if(e.target.checked) {
      setNewCrews(draft => {
        draft.push(crew)
      })
    } else {
      setNewCrews(draft => {
        return draft.filter(_crew => _crew.id !== crew.id)
      })
    }
  }

  const openCrewModal = () => {
    if(newMembers.length > 0) {
      setShowCrewsModal(() => true)
    } else {
      Toast.error("Please select at least one member")
    }
  }

  const closeCrewModal = () => {
    if(!isFetching) {
      setShowCrewsModal(() => false)
      setNewMembers(draft => draft = [])
      setNewCrews(draft => draft = [])
    }
  }

  const assingMembers = async () => {
    setIsFetching(draft => true);

    try {
      await Promise.all(
        newCrews.map(async crew => {
          await requestUpdateCrew(crew.id, {
            ...crew,
            assignedUsersIds: Array.from(new Set([
              ...crew.assignedAuthzIds,
              ...newMembers
            ]))
          })
        })
      )
    } catch (e) {
      setIsFetching(draft => false);
      closeCrewModal()
      Toast.error("Something went wrong")
      return
    }

    setIsFetching(draft => false);
    closeCrewModal()
    Toast.success("Members assgined")
  }

  return <Page>
    <TabContainer tabs={[
      <Tab type="red" to="/crews/all">ALL CREWS</Tab>,
      <Tab type="blue" to="/crews/all-members">ALL MEMBERS</Tab>,
      <Tab type="green" to="#" data-active>UNASSIGNED</Tab>
    ]}>
      <Wrapper>
        <Spacer height="50"/>
        <Text>NEW Members</Text>
      </Wrapper>
      <Spacer height="38"/>
      <div>
        {users.map(member => (
          <AssignRow key={member.id}>
            <Checkbox onChange={e => handleAssigneeChange(e, member.authzId)} checked={newMembers.indexOf(member.authzId) !== -1}/>
            <Spacer width="74"/>
            <PersonData>
              <Name>{member.name}</Name>
              <Company>{member?.user_metadata?.company}</Company>
            </PersonData>
          </AssignRow>
        ))}
      </div>
      <Spacer height="50"/>
      <ButtonWrapper>
        <Button width="287px" onClick={openCrewModal} type="white">ASSIGN CREW</Button>
      </ButtonWrapper>
      <Spacer height="35"/>
      <Modal show={showCrewsModal} close={closeCrewModal} title="CREW NAME">
        <AssignTable>
          {crews.map(crew => (
              <CrewRow key={crew.id}>
                <CrewTitle>{crew.name}</CrewTitle>
                <CrewData>
                  <CrewCount>{crew.assignedAuthzIds.length}</CrewCount>
                  <Spacer width="75"/>
                  <Checkbox onChange={e => handleCrewChange(e, crew)} checked={newCrews.find(_crew => _crew.id === crew.id)}/>
                </CrewData>
              </CrewRow>
          ))}
        </AssignTable>
        <Spacer height="35"/>
        <ModalButtonWrapper>
          <Button onClick={assingMembers} disabled={isFetching} width="287px">{isFetching ? 'Saving...' : 'Save'}</Button>
        </ModalButtonWrapper>
      </Modal>
    </TabContainer>
  </Page>
}


export default CrewsUnassigned